import { Loader2 } from "lucide-react";
import React from "react";




export default function Loading() {
  return (
   
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-[5px] bg-opacity-4">
    <div className="relative">
      <Loader2 className="w-16 h-16 animate-spin text-blue-500" />
      <div className="absolute top-0 left-0 w-16 h-16 animate-ping">
        <div className="absolute inset-0 rotate-0 scale-75 opacity-30 rounded-full bg-red-500"></div>
        <div className="absolute inset-0 rotate-45 scale-75 opacity-30 rounded-full bg-yellow-500"></div>
        <div className="absolute inset-0 -rotate-45 scale-75 opacity-30 rounded-full bg-green-500"></div>
      </div>
    </div>
    <span className="sr-only">Cargando, por favor espere</span>
    </div>
  );
}


import axios from "axios";

export const getNotificaciones = async (socioId: number, getAccessTokenSilently: any) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/notificaciones/socio/${socioId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const markNotificacionAsRead = async (notificacionId: number, getAccessTokenSilently: any) => {
    const token = await getAccessTokenSilently();
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/notificaciones/mark-as-read/${notificacionId}`,
            {}, // Enviar un cuerpo vacío si la API no requiere datos adicionales
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.status === 200; // Devuelve true si la solicitud fue exitosa
    } catch (error) {
        console.error("Error al marcar como leída:", error);
        return false; // Devuelve false si hubo un error
    }
};

export const deleteNotificacion = async (notificacionId: number, getAccessTokenSilently: any) => {
    const token = await getAccessTokenSilently();
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_BACK_URL}/notificaciones/${notificacionId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.status === 200; // Devuelve true si la solicitud fue exitosa
    } catch (error) {
        console.error("Error al eliminar la notificación:", error);
        return false; // Devuelve false si hubo un error
    }
};

import axios from 'axios';
import { Socio } from '../types/Socio.tsx'

type Cupon = {
  id: number;
  comercio: string;
  descripcion: string;
  descuento: number;
  fechaExpiracion: string;
  codigo: string;
};

export const guardarCupon = async (cupon: Omit<Cupon, "id">, token: string) => {
  try {
    console.log("Fecha original:", cupon.fechaExpiracion);
    
    // Intenta diferentes métodos de conversión
    const fechaParseada = new Date(cupon.fechaExpiracion);
    
    console.log("Fecha parseada:", fechaParseada);
    console.log("Fecha ISO:", fechaParseada.toISOString());
    console.log("Fecha formateada para SQL Server:", 
      fechaParseada.getFullYear() + '-' + 
      String(fechaParseada.getMonth() + 1).padStart(2, '0') + '-' + 
      String(fechaParseada.getDate()).padStart(2, '0')
    );

    const cuponConFechaFormateada = {
      ...cupon,
      fechaExpiracion: fechaParseada.toISOString().split('T')[0] // Formato YYYY-MM-DD
    };

    console.log("Cupón a enviar:", cuponConFechaFormateada);

    const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/cupones`, cuponConFechaFormateada, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error completo:", error);
    throw new Error(`Error al guardar el cupón: ${error.message}`);
  }
};

export const obtenerCupones = async (token: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/cupones`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener los cupones: ${error.message}`);
  }
};

export const eliminarCupon = async (cuponId: number, token: string) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BACK_URL}/cupones/${cuponId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error(`Error al eliminar el cupón: ${error.message}`);
  }
};

export const asignarCupon = async (socioId: number, cuponId: number, token: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACK_URL}/cupones/asignar`,
      { 
        socioId, 
        cuponId 
      },

      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al asignar cupón:", error);
    throw error;
  }
};

import React from "react";
import "./styles/App.css";
import Navbar from "./components/NavBar.tsx";
import Footer from "./components/Footer.tsx";
import AppRoutes from "./routes/AppRoutes.jsx";
import "./styles/tailwind.css";
import Titulo from "./components/head/Titulo.tsx";
function App() {

  return (
    <div className="App">
      <div className="min-h-screen">
        <Titulo texto={"Intercam"} />
        <Navbar />
        <AppRoutes />
      </div>
      <Footer />
    </div>
  );
}

export default App;

import axios from "axios";
import { Socio } from '../types/Socio.tsx'

const getUserProfile = async (
  email: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<Socio[]> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get<Socio[]>(
      `${process.env.REACT_APP_BACK_URL}/socio/email/${email}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el perfil de usuario:", error);
    throw error;
  }
};

const getSocioById = async (
  socio_id: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<Socio> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get<Socio>(
      `${process.env.REACT_APP_BACK_URL}/socio/${socio_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el perfil de usuario:", error);
    throw error;
  }
};

const saveSociosInLocalStorage = (socios: Socio[]) => {
  localStorage.setItem("socios", JSON.stringify(socios));
};

const getSociosFromLocalStorage = (): Socio[] | null => {
  const socios = localStorage.getItem("socios");
  return socios ? JSON.parse(socios) : null;
};

const saveSelectedSocioInLocalStorage = (socio: Socio) => {
  localStorage.setItem("socioSeleccionado", JSON.stringify(socio));
};

const getSelectedSocioFromLocalStorage = (): Socio | null => {
  const socio = localStorage.getItem("socioSeleccionado");
  return socio ? JSON.parse(socio) : null;
};
const clearSelectedSocioFromLocalStorage = () => {
  localStorage.removeItem("socioSeleccionado");
};

const clearLocalStorage = () => {
  localStorage.removeItem("socios");
  localStorage.removeItem("socioSeleccionado");
};

const autoSelectSingleSocio = () => {
  const socios = getSociosFromLocalStorage();
  if (socios && socios.length === 1) {
    saveSelectedSocioInLocalStorage(socios[0]);
    return socios[0];
  }
  return null;
};

const checkAndRedirectIfSocioSelected = (): boolean => {
  return !!getSelectedSocioFromLocalStorage();
};

const searchSocios = async (
  searchTerm: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<Socio[]> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get<Socio[]>(
      `${process.env.REACT_APP_BACK_URL}/socio/search`,
      {
        params: { search: searchTerm},
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el perfil de usuario:", error);
    throw error;
  }
};

const socioService = {
  getSocioById,
  getUserProfile,
  searchSocios,
  saveSociosInLocalStorage,
  getSociosFromLocalStorage,
  saveSelectedSocioInLocalStorage,
  getSelectedSocioFromLocalStorage,
  clearSelectedSocioFromLocalStorage,
  clearLocalStorage,
  autoSelectSingleSocio,
  checkAndRedirectIfSocioSelected,
};

export default socioService;

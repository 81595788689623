import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Package, Plus, Trash2 } from "lucide-react"; // Cambiar por el ícono que prefieras
import AsignarCuponModal from "./AsignarCuponModal.tsx";
import {
  guardarCupon,
  obtenerCupones,
  eliminarCupon,
} from "../services/cuponService.tsx";

type Cupon = {
  id: number;
  comercio: string;
  descripcion: string;
  descuento: number;
  fechaExpiracion: string;
  codigo: string;
};

const CuponesGestor: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [cupones, setCupones] = useState<Cupon[]>([]);
  const [filtro, setFiltro] = useState("");
  const [mostrarModal, setMostrarModal] = useState(false);
  const [cuponSeleccionado, setCuponSeleccionado] = useState<Cupon | null>(
    null
  );

  useEffect(() => {
    const cargarCupones = async () => {
      try {
        const token = await getAccessTokenSilently();
        const cupones = await obtenerCupones(token);
        setCupones(cupones);
      } catch (error) {
        console.error("Error al obtener los cupones:", error);
      }
    };
    cargarCupones();
  }, [getAccessTokenSilently]);

  const agregarCupon = async (nuevoCupon: Omit<Cupon, "id">) => {
    try {
      const token = await getAccessTokenSilently();
      const nuevoCuponConId = await guardarCupon(nuevoCupon, token);
      setCupones([...cupones, nuevoCuponConId]);
      setMostrarModal(false);
    } catch (error) {
      console.error("Error al agregar el cupón:", error);
    }
  };

  const eliminarCuponHandler = async (cuponId: number) => {
    try {
      const token = await getAccessTokenSilently();
      await eliminarCupon(cuponId, token);
      setCupones(cupones.filter((cupon) => cupon.id !== cuponId));
    } catch (error) {
      console.error("Error al eliminar el cupón:", error);
    }
  };

  const filtrarCupones = () => {
    return cupones.filter((cupon) =>
      cupon.comercio.toLowerCase().includes(filtro.toLowerCase())
    );
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Gestión de Cupones</h1>

      <div className="flex items-center space-x-4 mb-4">
        {/* Buscador */}
        <div className="flex-grow">
          <input
            type="text"
            placeholder="Buscar por comercio"
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        {/* Botón para agregar nuevo cupón */}
        <div>
          <button
            onClick={() => setMostrarModal(true)}
            className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
          >
            <span className="mr-2">
              <Plus className="w-4 h-4" />
            </span>
            Nuevo Cupón
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filtrarCupones().map((cupon) => (
          <div
            key={cupon.id}
            className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg"
          >
            <div className="mb-8 w-full">
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="relative w-64 h-32 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg shadow-lg overflow-hidden flex flex-col items-center justify-center">
                  <Package className="w-12 h-12 mb-2 text-white" />
                  <span className="text-2xl font-bold text-white">
                    {cupon.descuento}% de descuento
                  </span>
                  <span className="text-xs font-bold text-white">
                    Expira: {cupon.fechaExpiracion}
                  </span>
                  <div className="absolute top-0 right-0 w-16 h-16 bg-yellow-400 transform rotate-45 translate-x-8 -translate-y-8"></div>
                </div>
                <span className="text-sm font-bold text-gray-700">
                  {cupon.descripcion}
                </span>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <button
                onClick={() => setCuponSeleccionado(cupon)}
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
              >
                Asignar
              </button>
              <button
                onClick={() => eliminarCuponHandler(cupon.id)}
                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {mostrarModal && (
        <NuevoCuponModal
          onClose={() => setMostrarModal(false)}
          onSubmit={agregarCupon}
        />
      )}

      {cuponSeleccionado && (
        <AsignarCuponModal
          cuponId={cuponSeleccionado.id}
          onClose={() => setCuponSeleccionado(null)}
        />
      )}
    </div>
  );
};

interface NuevoCuponModalProps {
  onClose: () => void;
  onSubmit: (cupon: Omit<Cupon, "id">) => void;
}

const NuevoCuponModal: React.FC<NuevoCuponModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const [comercio, setComercio] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [descuento, setDescuento] = useState(0);
  const [fechaExpiracion, setFechaExpiracion] = useState("");
  const [codigo, setCodigo] = useState("");

const handleSubmit = () => {
  if (comercio && descripcion && descuento > 0 && fechaExpiracion && codigo) {
    console.log("Fecha a enviar:", fechaExpiracion);
    onSubmit({ 
      comercio, 
      descripcion, 
      descuento, 
      fechaExpiracion,
      codigo 
    });
  } else {
    alert("Todos los campos son obligatorios.");
  }
};

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Nuevo Cupón</h2>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Comercio"
            value={comercio}
            onChange={(e) => setComercio(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            placeholder="Descripción"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="number"
            placeholder="Descuento (%)"
            value={descuento}
            onChange={(e) => setDescuento(Number(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="date"
            value={fechaExpiracion}
            onChange={(e) => setFechaExpiracion(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            placeholder="Código"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mt-4 flex justify-end space-x">
         <button
         onClick={handleSubmit}
         className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
       >
         Guardar
       </button>
     </div>
   </div>
 </div>
);
};

export default CuponesGestor;
    
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginRequired from '../components/LoginRequired.tsx';
import Dashboard from '../components/Dashboard.tsx';
import CuotasTable from '../components/CuotasTable.tsx';
import NotificationsList from '../components/NotificationsList.tsx';
import Beneficios from '../components/Benefits.tsx';
import MisEventos from '../components/Events.tsx';
import Profile from '../components/Profile.tsx';
import SocioStatus from '../components/SocioStatus.tsx';
import SocioSelect from '../components/SocioSelect.tsx';
import ContratosTable from '../components/ContratosTable.tsx';
import AdminDashboard from '../components/AdminDashboard.tsx';
import CuponesGestor from '../components/Cupones.tsx';

const AppRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<LoginRequired />} />
        <Route path="/inicio" element={<Dashboard />} />
        <Route path="/cuotas" element={<CuotasTable />} />
        <Route path="/contratos" element={<ContratosTable />} />
        <Route path="/notificaciones" element={<NotificationsList />} />
        <Route path="/beneficios" element={<Beneficios />} />
        <Route path="/eventos" element={<MisEventos />} />
        <Route path="/perfil" element={<Profile />} />
        <Route path="/status" element={<SocioStatus />} />
        <Route path="/seleccionar-socio" element={<SocioSelect />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path="/cupones" element={<CuponesGestor/>} />
      </Routes>
  );
};

export default AppRoutes; 
import React from "react";
import { FaUsers, FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa"; // Usamos FontAwesome Icons


// Props para el componente
interface TimelineEventosProps {
  eventos: Evento[];
}

const TimelineEventos: React.FC<TimelineEventosProps> = ({ eventos }) => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="relative border-l-4 border-blue-500">
        {eventos.map((evento, index) => (
          <div key={evento.acontecimiento_id} className="mb-10 ml-6">
            {/* Punto en la línea de tiempo */}
            <div
              className={`absolute w-4 h-4 rounded-full -left-2 ${
                index % 2 === 0 ? "bg-blue-500" : "bg-green-500"
              }`}
            ></div>

            {/* Contenido del evento */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform duration-300 hover:scale-105">
              {evento.imagen && (
                <img
                  src={evento.imagen}
                  alt={evento.nombre}
                  className="w-full h-40 object-cover rounded-md mb-4"
                />
              )}
              <h2 className="text-xl font-bold text-gray-800 mb-2">{evento.nombre}</h2>
              <p className="text-gray-600 mb-4">{evento.descripcion}</p>
              <div className="flex items-center text-sm text-gray-500 mb-2">
                <FaCalendarAlt className="w-4 h-4 mr-2" />
                {(() => {
                  const date = evento.fecha.split("T")[0];
                  const [year, month, day] = date.split("-");
                  return `${day}/${month}/${year}`;
                })()}
              </div>
              <div className="flex items-center text-sm text-gray-500 mb-2">
                <FaMapMarkerAlt className="w-4 h-4 mr-2" />
                {evento.ubicacion.nombre}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <FaUsers className="w-4 h-4 mr-2" />
                {evento.socios.length} participantes
              </div>
              <button className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors">
                Ver más
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelineEventos;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Tipo para el contexto
interface AuthContextType {
  isAuthenticated: boolean;
  user: any;
  accessToken: string | null;
  isAdmin: boolean;
  handleLogout: () => void;
}

// Contexto de autenticación
const AuthContext = createContext<AuthContextType | undefined>(undefined);

const NAMESPACE = "https://intercam.camaracomercialbolivar.com.ar/roles";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading, getAccessTokenSilently, user, isAuthenticated: auth0IsAuthenticated, logout } = useAuth0();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);

  // Obtiene el token de acceso silenciosamente
  const fetchAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  // Inicializa el estado de autenticación
  useEffect(() => {
    const initializeAuthState = async () => {
      if (isLoading) return;

      if (auth0IsAuthenticated && user) {
        const roles = user[NAMESPACE] || [];
        setIsAuthenticated(true);
        setCurrentUser(user);
        setIsAdmin(roles.includes("Admin"));

        // Guarda en localStorage
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("user", JSON.stringify(user));

        await fetchAccessToken();
      } else {
        const storedAuth = localStorage.getItem("isAuthenticated") === "true";
        const storedUser = JSON.parse(localStorage.getItem("user") || "null");

        if (storedAuth && storedUser) {
          const roles = storedUser[NAMESPACE] || [];
          setIsAuthenticated(true);
          setCurrentUser(storedUser);
          setIsAdmin(roles.includes("Admin"));
        }
      }
    };

    initializeAuthState();
  }, [isLoading, auth0IsAuthenticated, user, getAccessTokenSilently]);

  // Manejo del logout
  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: "/login" });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user: currentUser, accessToken, isAdmin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para usar el contexto
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

import React, { useEffect, useState } from 'react';
import { Home, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import AcontecimientosService from "../services/acontecimientosService.tsx";
import { useAuth0 } from '@auth0/auth0-react';
import TimelineEventos from './TimelineEventos.tsx';
import  { Evento }  from '../types/Evento.tsx';
import Loading from './Loading.tsx';

export default function MisEventos({ onBack }: { onBack: () => void }) {
  const { getAccessTokenSilently } = useAuth0();
  const [eventos, setEventos] = useState<Evento[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const storedSocio = localStorage.getItem('socioSeleccionado');
        if (!storedSocio) {
          throw new Error('Socio no encontrado en el localStorage');
        }
        const socio = JSON.parse(storedSocio);
        const id = socio.socio_id;
        if (!id) {
          throw new Error('ID del socio no encontrado');
        }

        const data = await AcontecimientosService.getAcontecimientosSocios(id, getAccessTokenSilently);
        setEventos(data);
      } catch (error: any) {
        console.error('Error al cargar los eventos:', error.message);
      } finally { 
        setLoading(false);
      }

    };

    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <nav className="flex mb-6" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
                <Home className="w-4 h-4 mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRight className="w-4 h-4 text-gray-400" />
                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Mis Eventos</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <h1 className="text-3xl font-bold mb-6">Mis Eventos</h1>
        {
          loading ? <Loading /> : 
        
          <TimelineEventos eventos={eventos} />
        }
    </div>
  );
}

import React, { useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import socioService from '../services/socioService.tsx';
import { asignarCupon } from '../services/cuponService.tsx';

const AsignarCuponModal: React.FC<{ cuponId: number, onClose: () => void }> = ({ cuponId, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [listaSocios, setListaSocios] = useState([]);
  const [searchResults, setSearchResults] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchResults(true);
    setError(null);
    setListaSocios([]);
  
    if (!searchTerm.trim()) {
      setError("El campo de búsqueda no puede estar vacío.");
      setSearchResults(false);
      return;
    }
  
    try {
      // Utiliza directamente la función de socioService
      const data = await socioService.searchSocios(searchTerm, getAccessTokenSilently);
      
      console.log("Datos recibidos:", data);
      
      if (data.length === 0) {
        setError("No se encontraron socios que coincidan con la búsqueda.");
      } else {
        setListaSocios(data);
      }
    } catch (err) {
      console.error("Error completo:", err);
      setError("No se pudieron cargar los socios. Intenta nuevamente.");
    } finally {
      setSearchResults(false);
    }
  };

  const handleAssign = async (socioId: number) => {
    try {
      const token = await getAccessTokenSilently();
      await asignarCupon(socioId, cuponId, token);
      onClose();
    } catch (error) {
      setError("Error al asignar el cupón. Intenta nuevamente.");
      console.error("Error en la asignación:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Asignar Cupón a un Socio</h2>
        
        <form onSubmit={handleSearch} className="mb-4">
          <input
            type="text"
            placeholder="Buscar socio por nombre o ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors w-full"
          >
            Buscar
          </button>
        </form>

        {error && <p className="text-red-600 text-sm mb-4">{error}</p>}

        {searchResults ? (
          <p className="text-gray-600">Buscando socios...</p>
        ) : (
          <ul className="space-y-2">
            {listaSocios.map((socio: any) => (
              <li
                key={socio.id}
                className="p-2 border border-gray-300 rounded hover:bg-gray-100 cursor-pointer"
                onClick={() => handleAssign(socio.id)}
              >
                {socio.socio_nombre} 
              </li>
            ))}
          </ul>
        )}

        <button
          onClick={onClose}
          className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 w-full mt-4"
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default AsignarCuponModal;
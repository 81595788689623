import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import socioService from "../services/socioService.tsx";
import LogoCamara from "../assets/LogoCamara.png";
import { Lock } from "lucide-react";
import Loading from "./Loading.tsx";
import { useAuth } from "../context/AuthProvider.tsx";
import { useAuth0 } from "@auth0/auth0-react";

export default function LoginRequired() {
  const {  isAuthenticated, accessToken, user, isAdmin } = useAuth(); 
  const { loginWithRedirect  } = useAuth0();
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  
  const fetchUserData = async () => {
    if (isAuthenticated && user) {
      try {
        setLoading(true);
        debugger;
        const data = await socioService.getUserProfile(`${user.email}`, getAccessTokenSilently);

        if (data && data.length > 0) {
          socioService.saveSociosInLocalStorage(data);

          if (data.length === 1) {
            socioService.saveSelectedSocioInLocalStorage(data[0]);
          }
        }
      } catch (error) {
        console.error("Error al obtener los datos del socio:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [isAuthenticated, user, accessToken]);

  if (!isAuthenticated) {
    return (
      <div className="bg-gray-100 min-h-screen flex items-center justify-center p-4">
        <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 sm:p-8 md:p-12 lg:p-16">
            <div className="flex flex-col items-center mb-8 md:mb-12">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-800 mb-4 md:mb-6 text-center">
                Intercamaras Web
              </h2>
              <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 mb-4 md:mb-6 relative">
                <img
                  src={LogoCamara}
                  alt="Camara Comercial Bolivar"
                  className="w-full h-full object-contain"
                />
              </div>
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 text-center">
                Cámara Comercial e Industrial de Bolívar
              </h1>
            </div>
            <p className="text-gray-600 mb-8 md:mb-12 text-center text-base sm:text-lg md:text-xl">
              Para continuar navegando y acceder a todas las funcionalidades,
              por favor inicia sesión.
            </p>
            <div className="flex justify-center">
              <button
                onClick={() => loginWithRedirect()}
                className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 md:py-4 md:px-8 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-base sm:text-lg md:text-xl"
              >
                <Lock className="w-5 h-5 mr-2 md:w-6 md:h-6 md:mr-3" />
                Iniciar sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (isAdmin) {
    return <Navigate to="/admin" />;
  } else {
    const socios = JSON.parse(localStorage.getItem("socios") || "[]");
    const selectedSocio = JSON.parse(
      localStorage.getItem("socioSeleccionado") || "null"
    );
    if (socios.length > 1) {
      return <Navigate to="/seleccionar-socio" />;
    }
    if (selectedSocio) {
      return <Navigate to="/inicio" />;
    }
    if (socios.length === 0) {
      return <Navigate to="/status" />;
    }
  }
}
